html,
body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

body {
    display: flex;
}

div[contenteditable="true"] {
    max-width: 18ch;
}


#buttons {
    position: fixed;
    bottom: 20px;
    left: 20px;
}

#buttons > button {
    display: inline-block;
    height: 21px;
    vertical-align: top;
}

#align-left > .icon {
    background: url("./icons/align-left-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#align-center .icon {
    background: url("./icons/align-horizontal-center-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#align-right .icon {
    background: url("./icons/align-right-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#align-top .icon {
    background: url("./icons/align-top-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#align-middle .icon {
    background: url("./icons/align-vertical-center-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#align-bottom .icon {
    background: url("./icons/align-bottom-tool.svg") no-repeat center;
    background-size: contain;
    display: block;
    height: 15px;
    width: 15px;
}

#canvas {
    flex-grow: 1;
    height: 95%;
}

#properties {
    background: #f8f8f8;
    border-left: solid 1px #ccc;
    padding-right: 10px;
    overflow-y: scroll;
}
